import axios from '@/config/app';
import ResourceService from '@/services/resource.service';

export default class TagService extends ResourceService {
    getAll(config = {}) {
        return axios.get('api/v1/tags', config);
    }

    getItemRequestUri(id) {
        return `api/v1/tags/${id}`;
    }

    getItem(id, filters = {}) {
        return axios.get(this.getItemRequestUri(id));
    }

    save(data, isNew = false) {
        return axios.post('api/v1/tags', data);
    }

    update(data, id) {
        return axios.put(`api/v1/tags/${id}`, data);
    }

    deleteItem(id) {
        return axios.delete(`api/v1/tags/${id}`);
    }

    getWithFilters(filters, config = {}) {
        return axios.get('api/v1/tags', filters, config);
    }

    addTags(data) {
        return axios.post('api/v1/add-tag', { tag: data });
    }
}
