export default class SprintPolicy {
    static viewAny(user) {
        return user.roles_access.is_admin || user.roles_access.is_manager || user.roles_access.is_reporter;
    }

    static create(user) {
        return user.roles_access.is_admin || user.roles_access.is_manager;
    }

    static update(user, _model) {
        return user.roles_access.is_admin || user.roles_access.is_manager;
    }

    static delete(user, _model) {
        return user.roles_access.is_admin || user.roles_access.is_manager;
    }
}
