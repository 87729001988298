import store from '@/store';

export default class RolePolicy {
    static haveRole(rolesName) {
        let user =
            Object.keys(store.getters['user/user']).length === 0
                ? JSON.parse(localStorage.getItem('user'))
                : store.getters['user/user'];
        let company = store.getters['company/company']
            ? store.getters['company/company']
            : JSON.parse(localStorage.getItem('company'));

        const userRoles = user.roles
            .filter(value => value.company_id === company.id)
            .flatMap(company => company.roles)
            .map(role => role.attributes.name);
        return userRoles.some(name => rolesName.includes(name));
    }
}
