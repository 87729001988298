import axios from 'axios';

class SprintService {
    update(data, id) {
        return axios.put(`/api/v1/sprints/${id}`, data);
    }
    save(data) {
        return axios.post('/api/v1/sprints', data);
    }
    getAll() {
        return axios.get('/api/v1/sprints');
    }
    getWithFilters(filters) {
        return axios.get('/api/v1/sprints', { data: filters });
    }
    getById(id) {
        return axios.get(`/api/v1/sprints/${id}`);
    }
    getItem(id) {
        return this.getById(id);
    }

    deleteItem(id) {
        return axios.delete(`/api/v1/sprints/${id}`);
    }
}

export default SprintService;
