import store from '@/store';

export default class SubscriptionPolicy {
    static viewTab(planName) {
        let company = store.getters['company/company'] || JSON.parse(localStorage.getItem('company'));
        const subType = {
            basicPlan: ['Basic', 'Premium', 'Enterprise', 'Development'],
            premiumPlan: ['Premium', 'Enterprise', 'Development'],
            enterprisePlan: ['Enterprise', 'Development'],
            developmentPlan: ['Development'],
        };

        if (company) {
            try {
                const subscriptionCheck = this.subscription(company.subscription, subType[planName]);
                return subscriptionCheck;
            } catch (error) {
                console.error(error);
                return false;
            }
        }
    }

    static subscription(subscription, names) {
        if (subscription) {
            if (subscription.attributes.active) {
                return names.includes(subscription.attributes.subscription.name);
            }
        }
        return false;
    }
}
