import moment from 'moment-timezone';
import SprintService from './services/sprint.service';
import ProjectsService from '@/services/resource/project.service';
import DatePicker from '../Settings/components/DatePicker.vue';
import { formatDurationString } from '@/utils/time';
import SubscriptionPolicy from '../../policies/subscription.policy';
import RolePolicy from '../../policies/role.policy';
import i18n from '@/i18n';
import './policies';

export const SUBSCRIPTION_PLAN = 'developmentPlan';
export const ROLES_ACCESS = ['admin', 'accountant', 'reporter'];

export default {
    SUBSCRIPTION_PLAN: SUBSCRIPTION_PLAN,
    ROLES_ACCESS: ROLES_ACCESS,
};

export const ModuleConfig = {
    routerPrefix: 'sprints',
    loadOrder: 24,
    moduleName: 'Sprints',
};

function formatDateTime(value, timezone) {
    const date = moment.tz(value, timezone || moment.tz.guess());
    return date.locale(i18n.locale).format('MMMM D, YYYY — HH:mm:ss ([GMT]Z)');
}

export function init(context, router) {
    context.addRedirectPath({ path: '/sprints/view/', withID: true });
    const crud = context.createCrud('sprints.crud-title', 'sprints', SprintService);
    const crudViewRoute = crud.view.getViewRouteName();
    const crudEditRoute = crud.edit.getEditRouteName();
    const crudNewRoute = crud.new.getNewRouteName();

    const navigation = { view: crudViewRoute, edit: crudEditRoute, new: crudNewRoute };

    crud.view.addToMetaProperties('titleCallback', ({ values }) => values.name, crud.view.getRouterConfig());
    crud.view.addToMetaProperties('navigation', navigation, crud.view.getRouterConfig());

    crud.new.addToMetaProperties('permissions', 'sprints/create', crud.new.getRouterConfig());
    crud.new.addToMetaProperties('navigation', navigation, crud.new.getRouterConfig());

    crud.edit.addToMetaProperties('permissions', 'sprints/edit', crud.edit.getRouterConfig());

    const grid = context.createGrid('sprints.grid-title', 'sprints', SprintService, {
        with: ['users', 'defaultPriority', 'statuses', 'can'],
        withCount: ['tasks'],
    });
    grid.addToMetaProperties('navigation', navigation, grid.getRouterConfig());

    const fieldsToShow = [
        {
            label: 'field.name',
            key: 'name',
        },
        {
            label: 'field.start_date',
            key: 'start_date',
            render: (h, { currentValue, companyData }) => {
                return h('span', formatDateTime(currentValue, companyData.timezone));
            },
        },
        {
            label: 'field.end_date',
            key: 'end_date',
            render: (h, { currentValue, companyData }) => {
                return h('span', formatDateTime(currentValue, companyData.timezone));
            },
        },
        {
            label: 'field.project_name',
            key: 'project_name',
            render: (h, props) => {
                return h(
                    'router-link',
                    {
                        props: {
                            to: `/projects/view/${props.values.project_id}`,
                        },
                    },
                    props.values.project_name,
                );
            },
        },
        {
            label: 'field.total_tasks',
            key: 'total_tasks',
            render: (h, { values }) => {
                return h('span', values.stats.total_tasks);
            },
        },
        {
            label: 'field.completed_tasks',
            key: 'completed_tasks',
            render: (h, { values }) => {
                return h('span', values.stats.completed_tasks);
            },
        },
        {
            label: 'field.completion_rate',
            key: 'completion_rate',
            render: (h, { values }) => {
                return h('span', values.stats.completion_rate + ' %');
            },
        },
        {
            key: 'stats',
            label: 'field.tasks_stats',
            render: (h, props) => {
                const data = [];
                Object.keys(props.values.tasks).forEach(k => {
                    props.values.tasks[k].time = formatDurationString(
                        +props.values.tasks[k].attributes.total_spent_time,
                    );
                    data.push(props.values.tasks[k]);
                });
                return h('AtTable', {
                    props: {
                        columns: [
                            {
                                title: i18n.t('field.task'),
                                render: (h, { item }) => {
                                    return h(
                                        'router-link',
                                        {
                                            props: {
                                                to: `/tasks/view/${item.attributes.id}`,
                                            },
                                        },
                                        item.attributes.task_name,
                                    );
                                },
                            },
                            {
                                title: i18n.t('field.estimated_points'),
                                render: (h, { item }) => {
                                    return h('span', item.attributes.estimated_points);
                                },
                            },
                            {
                                title: i18n.t('field.actual_points'),
                                render: (h, { item }) => {
                                    return h('span', item.attributes.actual_points);
                                },
                            },
                            {
                                key: 'time',
                                title: i18n.t('field.time'),
                            },
                        ],
                        data,
                    },
                });
            },
        },
        {
            label: 'field.total_estimated_points',
            key: 'total_estimated_points',
            render: (h, { values }) => {
                return h('span', values.stats.total_estimated_points);
            },
        },
        {
            label: 'field.total_actual_points',
            key: 'total_actual_points',
            render: (h, { values }) => {
                return h('span', `${values.stats.completed_actual_points} / ${values.stats.total_actual_points}`);
            },
        },
        {
            label: 'field.created_at',
            key: 'created_at',
            render: (h, { currentValue, companyData }) => {
                return h('span', formatDateTime(currentValue, companyData.timezone));
            },
        },
        {
            label: 'field.updated_at',
            key: 'updated_at',
            render: (h, { currentValue, companyData }) => {
                return h('span', formatDateTime(currentValue, companyData.timezone));
            },
        },
    ];

    const fieldsToFill = [
        {
            key: 'id',
            displayable: false,
        },
        {
            label: 'field.project',
            key: 'project_id',
            type: 'resource-select',
            service: new ProjectsService(),
            required: true,
        },
        {
            label: 'field.name',
            key: 'name',
            type: 'text',
            placeholder: 'field.name',
            required: true,
        },
        {
            label: 'field.start_date',
            key: 'start_date',
            placeholder: 'field.start_date',
            render: (h, props) => {
                const value = props.values.start_date ?? '';

                return h(DatePicker, {
                    props: {
                        initialDate: value,
                    },
                    on: {
                        input(value) {
                            props.inputHandler(value);
                        },
                    },
                });
            },
        },
        {
            label: 'field.end_date',
            key: 'end_date',
            render: (h, props) => {
                const value = props.values.end_date ?? '';

                return h(DatePicker, {
                    props: {
                        initialDate: value,
                    },
                    on: {
                        input(value) {
                            props.inputHandler(value);
                        },
                    },
                });
            },
        },
    ];

    crud.view.addField(fieldsToShow);
    crud.new.addField(fieldsToFill);
    crud.edit.addField(fieldsToFill);

    grid.addColumn([
        {
            title: 'field.name',
            key: 'name',
            render: (h, { item }) => {
                return h('span', item.attributes.name);
            },
        },
        {
            title: 'field.start_date',
            key: 'start_date',
            render: (h, { item }) => {
                return h('span', item.attributes.start_date);
            },
        },
        {
            title: 'field.end_date',
            key: 'end_date',
            render: (h, { item }) => {
                return h('span', item.attributes.end_date);
            },
        },
        {
            title: 'field.project',
            key: 'project_name',
            render: (h, { item }) => {
                return h('span', item.attributes.project_name);
            },
        },
    ]);

    grid.addFilter([
        {
            referenceKey: 'name',
            filterName: 'filter.fields.sprint_name',
        },
    ]);

    grid.addAction([
        {
            title: 'control.view',
            icon: 'icon-eye',
            onClick: (router, { item }, context) => {
                context.onView(item.attributes);
            },
            renderCondition({ $can }, item) {
                return $can('viewAny', 'sprint', item.attributes);
            },
        },
        {
            title: 'control.edit',
            icon: 'icon-edit',
            onClick: (router, { item }, context) => {
                context.onEdit(item.attributes);
            },
            renderCondition: ({ $can }, item) => {
                return $can('update', 'sprint', item.attributes);
            },
        },
        {
            title: 'control.delete',
            actionType: 'error',
            icon: 'icon-trash-2',
            onClick: (router, { item }, context) => {
                context.onDelete(item.attributes);
            },
            renderCondition: ({ $can }, item) => {
                return $can('delete', 'sprint', item.attributes);
            },
        },
    ]);

    grid.addPageControls([
        {
            label: 'control.create',
            type: 'primary',
            icon: 'icon-edit',
            onClick: ({ $router }) => {
                $router.push({ name: crudNewRoute });
            },
            renderCondition: ({ $can }) => {
                return $can('create', 'sprint');
            },
        },
    ]);

    context.addRoute(crud.getRouterConfig());
    context.addRoute(grid.getRouterConfig());

    // context.addNavbarEntry({
    //     label: 'navigation.sprints',
    //     to: {
    //         name: 'Sprints.crud.sprints',
    //     },
    //     displayCondition: () => SubscriptionPolicy.viewTab(SUBSCRIPTION_PLAN) && RolePolicy.haveRole(ROLES_ACCESS),
    // });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
