import SubscriptionPolicy from '../../policies/subscription.policy';

export const ModuleConfig = {
    routerPrefix: 'service',
    loadOrder: 23,
    moduleName: 'service',
};

export function init(context) {
    context.addRoute({
        path: '/services',
        name: 'services',
        component: () => import(/* webpackChunkName: "service" */ './views/Service.vue'),
        meta: {
            auth: true,
            BasicSubscription: true,
        },
        children: [
            {
                path: 'list',
                name: 'services.list',
                component: () => import(/* webpackChunkName: "service" */ './views/services/List.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () => SubscriptionPolicy.viewTab('basicPlan'),
                },
            },
            {
                path: 'new',
                name: 'services.new',
                component: () => import(/* webpackChunkName: "service" */ './views/services/New.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () => SubscriptionPolicy.viewTab('basicPlan'),
                },
            },
            {
                path: 'view/:id',
                name: 'services.view',
                component: () => import(/* webpackChunkName: "service" */ './views/services/View.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () => SubscriptionPolicy.viewTab('basicPlan'),
                },
            },
            {
                path: 'edit/:id',
                name: 'services.edit',
                component: () => import(/* webpackChunkName: "service" */ './views/services/Edit.vue'),
                meta: {
                    auth: true,
                    BasicSubscription: true,
                    checkPermission: () => SubscriptionPolicy.viewTab('basicPlan'),
                },
            },
        ],
    });

    context.addNavbarEntry({
        label: 'navigation.services',
        to: {
            path: '/services/list',
        },
        displayCondition: () => {
            return SubscriptionPolicy.viewTab('basicPlan');
        },
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
