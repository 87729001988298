export default class UserPolicy {
    static viewAny(user) {
        return user.roles_access.is_admin;
    }

    static create(user) {
        return user.roles_access.is_admin;
    }

    static update(user) {
        return user.roles_access.is_admin;
    }
}
